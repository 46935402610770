import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { GenericPage } from "../../../components/mdx/page";
import { Breadcrumbs } from "../../../components/widgets/breadcrumbs";
import { Aside } from "../../../components/widgets/aside";
import { Border } from "../../../components/widgets/container";
import { ButtonPrimary, ButtonSecondary } from "../../../components/widgets/button";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = GenericPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`How Our Portfolios Work`}</h1>
    <Breadcrumbs text="Help" to="/help/" mdxType="Breadcrumbs" />
    <h2>{`What We Do`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/find-your-portfolio/"
          }}>{`How to choose your portfolio`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/investment-dashboard/"
          }}>{`How to manage your investments`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/help/account-rebalancing/"
          }}>{`How to rebalance your account`}</a></li>
      </ul>
    </Aside>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` is the premier site for DIY investors seeking to invest in tactical portfolios. `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/t/tacticalassetallocation.asp"
      }}>{`Tactical asset allocation`}</a>{`, an investment approach that adjusts portfolio allocations to take advantage of profitable market opportunities, and reduce painful losses. To do so, we run complex computer simulations daily, calculating the best assets to hold for each portfolio. We provide these asset allocations on the site, and send our members email notifications when it is time to rebalance. Our members then place the orders themselves in their own brokerage accounts.`}</p>
    <div css={`clear:both;`} />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c728eba2210ca3ddb6e006de82487800/3332d/jeshoots-com-fzOITuS1DIQ-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAC/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAATLSWDBJ/8QAGhAAAgMBAQAAAAAAAAAAAAAAAQIAESIDI//aAAgBAQABBQJ3brCmSSsbL151P//EABcRAAMBAAAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8BUbP/xAAXEQADAQAAAAAAAAAAAAAAAAABAhAR/9oACAECAQE/AS2T/8QAGhAAAwEAAwAAAAAAAAAAAAAAAAERIRASYf/aAAgBAQAGPwK3BdnfERYJC3j/xAAZEAEBAQEBAQAAAAAAAAAAAAABEQAhMVH/2gAIAQEAAT8hV4z7dFeXCfUfDKBZMUqshXf/2gAMAwEAAgADAAAAEHcf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAwEBPxBtvCaf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERUf/aAAgBAgEBPxBUrSn/xAAbEAEBAQEBAAMAAAAAAAAAAAABEQAhMVFhkf/aAAgBAQABPxCfIDwQw9yZ0f1+jL29Z4a8azKEt+DI9VHo9NVrxTf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "improve your risk-adjusted return",
            "title": "improve your risk-adjusted return",
            "src": "/static/c728eba2210ca3ddb6e006de82487800/e5166/jeshoots-com-fzOITuS1DIQ-unsplash.jpg",
            "srcSet": ["/static/c728eba2210ca3ddb6e006de82487800/f93b5/jeshoots-com-fzOITuS1DIQ-unsplash.jpg 300w", "/static/c728eba2210ca3ddb6e006de82487800/b4294/jeshoots-com-fzOITuS1DIQ-unsplash.jpg 600w", "/static/c728eba2210ca3ddb6e006de82487800/e5166/jeshoots-com-fzOITuS1DIQ-unsplash.jpg 1200w", "/static/c728eba2210ca3ddb6e006de82487800/d9c39/jeshoots-com-fzOITuS1DIQ-unsplash.jpg 1800w", "/static/c728eba2210ca3ddb6e006de82487800/df51d/jeshoots-com-fzOITuS1DIQ-unsplash.jpg 2400w", "/static/c728eba2210ca3ddb6e006de82487800/3332d/jeshoots-com-fzOITuS1DIQ-unsplash.jpg 5600w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The Benefits of Tactial Asset Allocation`}</h2>
    <p>{`In a nutshell, investing is the process of buying assets, holding them for some time, and liquidating them at a later point for profit. The profit may come in the form of capital gains, interest paid, or a combination of both. Not all assets are the same, though. Instead, they differ in their risk, return, and corrrelation to each other. The traditional way of investing, introduced in the 1950s by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Harry_Markowitz"
      }}>{`Harry Markowitz`}</a>{` and his `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Modern_portfolio_theory"
      }}>{`Modern Portfolio Theory`}</a>{`, aims to optimally combine assets into portfolios, and reaping the benefits of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Diversification_(finance)"
      }}>{`diversification`}</a>{`, "the only free lunch" in investing. This investment paradigm, also advocated by `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/John_C._Bogle"
      }}>{`Jack Bogle`}</a>{`, suggests to hold onto the assets, and "stay the course." One of the most-often quoted portfolios of this style is the `}<a parentName="p" {...{
        "href": "/portfolios/classic-60-40"
      }}>{`ubiquituous 60/40 portfolio`}</a>{`. History tells us that such balanced portfolios can indeed dampen the market's volatility, but still suffer from painful losses.`}</p>
    <p>{`Successful investors like `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Warren_Buffett"
      }}>{`Warren Buffett`}</a>{`, or `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Jim_Simons_(mathematician)"
      }}>{`Jim Simons`}</a>{` have taken a different approach. Instead of holding on to their assets for an indefinite time, they rotate their assets as they see fit, an investment approach known as `}<a parentName="p" {...{
        "href": "https://www.investopedia.com/terms/t/tacticalassetallocation.asp"
      }}>{`tactical asset allocation`}</a>{`. Buffet and Simons both have been beating the markets for decades in a row, directly contradicting the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Efficient-market_hypothesis"
      }}>{`efficient market hypothesis`}</a>{`. `}</p>
    <p>{`At its core, tactical asset allocation exploits `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Market_anomaly"
      }}>{`market anomalies`}</a>{`. These anomalies include `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Momentum_investing"
      }}>{`momentum`}</a>{`, `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Mean_reversion_(finance)"
      }}>{`mean-reversion`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://quantpedia.com/an-analysis-of-volatility-clustering-of-equity-factor-strategies/"
      }}>{`volatility clustering`}</a>{`. By designing portfolios based on these observed anomalies, it is possible to reduce `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Drawdown_(economics)"
      }}>{`portfolio drawdowns`}</a>{`, and to reap outsized returns. As a result, tactical portfolios promise better `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Sharpe_ratio"
      }}>{`risk adjusted returns`}</a>{`, lower drawdowns, and faster recovery, making investing more enjoyable and successful, while at the same time lowering stress.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/130d9bd822838fc60d27c3ad6fa40d4b/67226/markus-spiske-3Tf1J8q9bBA-unsplash.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAgADBP/EABYBAQEBAAAAAAAAAAAAAAAAAAACA//aAAwDAQACEAMQAAAB7i9M7E4//8QAGhAAAgIDAAAAAAAAAAAAAAAAAAIBERAhMf/aAAgBAQABBQLVSosNSnMf/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGBAAAwEBAAAAAAAAAAAAAAAAAAEhIKH/2gAIAQEABj8CJGV8x//EABoQAQEAAwEBAAAAAAAAAAAAAAEAIUFRcRH/2gAIAQEAAT8hAgz7C59JbDZodvhE7f/aAAwDAQACAAMAAAAQ+C//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQIBAT8QjX//xAAdEAACAQUBAQAAAAAAAAAAAAABEQAhMUFxkVHB/9oACAEBAAE/EHoJbHsNB/tIn4YFld1t2IaFAeohZs5jTUSdqf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "strategic asset allocation",
            "title": "strategic asset allocation",
            "src": "/static/130d9bd822838fc60d27c3ad6fa40d4b/e5166/markus-spiske-3Tf1J8q9bBA-unsplash.jpg",
            "srcSet": ["/static/130d9bd822838fc60d27c3ad6fa40d4b/f93b5/markus-spiske-3Tf1J8q9bBA-unsplash.jpg 300w", "/static/130d9bd822838fc60d27c3ad6fa40d4b/b4294/markus-spiske-3Tf1J8q9bBA-unsplash.jpg 600w", "/static/130d9bd822838fc60d27c3ad6fa40d4b/e5166/markus-spiske-3Tf1J8q9bBA-unsplash.jpg 1200w", "/static/130d9bd822838fc60d27c3ad6fa40d4b/d9c39/markus-spiske-3Tf1J8q9bBA-unsplash.jpg 1800w", "/static/130d9bd822838fc60d27c3ad6fa40d4b/df51d/markus-spiske-3Tf1J8q9bBA-unsplash.jpg 2400w", "/static/130d9bd822838fc60d27c3ad6fa40d4b/67226/markus-spiske-3Tf1J8q9bBA-unsplash.jpg 3072w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`The Benefits of Quantitative Trading`}</h2>
    <p>{`Tactical asset allocation requires to rotate assets when market conditions change. This requires a method of making these trading decisions. There is a virtually unlimited number of approaches to making investment decisions, but they can be distinguished in two main categories: `}<a parentName="p" {...{
        "href": "https://www.cmegroup.com/education/courses/trade-and-risk-management/system-based-vs-discretionary-trading.html"
      }}>{`System-based vs discretionary`}</a>{`.`}</p>
    <p><em parentName="p">{`TuringTrader.com`}</em>{` is a system-based platform. Every strategy on our site is described by a fixed set of rules, coded in software. This approach is called quantitative trading. `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Algorithmic_trading"
      }}>{`Algorithmic trading`}</a>{` offers unique benefits:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`free of emotions`}</strong>{`. Unlike discretionary trading, where information is evaluated subjectively, quant trading is deterministic and analytical. There are no emotions, no fearm no greed, and no ambiguity. Everything is numbers and software.`}</li>
      <li parentName="ul"><strong parentName="li">{`learn from the past`}</strong>{`. Because in quant trading the rules don't evolve over time, we can test trading ideas with historical data. By replaying historical situations, quants can learn the best ways to react to them, and make better decisions in the future. It would take more than a lifetime of discretionary trading to gather this level of insight.`}</li>
      <li parentName="ul"><strong parentName="li">{`know your investments`}</strong>{`. By simulating portfolios with historical data we also gain unprecedented insight into the characteristics of our investments: we know how they react to recessions, or actions by the Federal Reserve. We know their historical returns, and volatility, the severity of their drawdowns, and the time to recover. These data help tremendously in picking the right portfolios for your investment objective.`}</li>
    </ul>
    <h2>{`Our Selection of Portfolios`}</h2>
    <Aside mdxType="Aside">
      <p><strong parentName="p">{`See also`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/sign-up/"
          }}>{`Our membership plans`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/articles/all-stars-portfolios/"
          }}>{`About All-Stars portfolios`}</a></li>
      </ul>
    </Aside>
    <p>{`On `}<em parentName="p">{`TuringTrader.com`}</em>{`, we track more than three dozen portfolios. We can distinguish three different categories:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Basic portfolios`}</strong>{`. We took these portfolios from books and publications. Because we are resting on the fine work of others, we are providing these portfolios free of charge, link to the original source, and even share our source code for most of them.`}</li>
      <li parentName="ul"><strong parentName="li">{`Premium portfolios`}</strong>{`. These portfolios are our original work. While we often use published work as a starting point, we have added our proprietary secret source to expand on this work. Access to these portfolios requires a `}<em parentName="li">{`Premium`}</em>{` subscription.`}</li>
      <li parentName="ul"><strong parentName="li">{`All-Stars portfolios`}</strong>{`. These portfolios are meta-portfolios, combining multiple premium portfolios in a convenient package. Thanks to their increased diversification, these portfolios provide better risk-adjusted returns, and can cope with a wider range of market scenarios.`}</li>
    </ul>
    <p>{`In summary, `}<em parentName="p">{`TuringTrader.com`}</em>{` aims at DIY investors looking to supercharge their abilities. By following one of our portfolios, you have access to the same techniques used by professional fund managers, but with much lower fees and more flexibility.`}</p>
    <ButtonPrimary text="explore our portfolios" to="/portfolios/" mdxType="ButtonPrimary" />
    <br /><br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      